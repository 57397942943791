<template>
  <div>
    <b-row class="mb-2">
      <b-col
        cols="12"
        sm="6"
      >
        <div class="form-group">
          <label>Filter Distribution</label>
          <b-select
            v-model="filter.distribution"
            :options="filterDistributions"
            @change="onFilterChange"
          />
        </div>
      </b-col>
      <b-col
        cols="12"
        sm="6"
      >
        <div class="form-group">
          <label>Search String</label>
          <b-input
            v-model="tableSettings.filter"
            type="text"
            placeholder="Enter search string"
            debounce="1000"
          />
        </div>
      </b-col>
    </b-row>

    <b-table
      ref="table"
      hover
      responsive
      class="mt-2"
      :per-page="tableSettings.perPage"
      :current-page="tableSettings.currentPage"
      :items="myProvider"
      :fields="tableSettings.fields"
      :sort-by.sync="tableSettings.sortBy"
      :sort-desc.sync="tableSettings.sortDesc"
      :sort-direction="tableSettings.sortDirection"
      :filter="tableSettings.filter"
      :filter-included-fields="tableSettings.filterOn"
      show-empty
    >
      <template #cell(index)="data">
        {{ tableSettings.currentPage * tableSettings.perPage - tableSettings.perPage + (data.index + 1) }}
      </template>

      <template #cell()="data">
        <div class="text-nowrap">
          {{ data.value }}
        </div>
      </template>

      <template #cell(status)="data">
        <div class="text-nowrap">
          {{ getStatus(data.item) }}
        </div>
      </template>

      <template #cell(warehouse_pick_up)="data">
        <span :class="data.item.warehouse_pick_up == 1 ? 'text-success' :'text-danger' "> {{ data.item.warehouse_pick_up === 1 ? 'Yes' : 'No' }}</span>
      </template>

      <template #cell(is_close)="data">
        <span :class="data.item.is_close == 1 ? 'text-success' :'text-danger' "> {{ data.item.is_close === 1 ? 'Yes' : 'No' }}</span>
      </template>

      <template #cell(actions)="row">
        <div class="text-nowrap">
          <b-button
            size="sm"
            @click="showSODetailsForm(row.item)"
          >
            show details
          </b-button>
        </div>
      </template>
    </b-table>

    <b-row>
      <b-col
        cols="12"
        sm="6"
      >
        <div class="w-100 w-sm-25 mb-2 sm-mb-2">
          <b-select
            v-model="tableSettings.perPage"
            :options="tableSettings.pageOptions"
            size="sm"
          />
        </div>
      </b-col>
      <b-col
        cols="12"
        sm="6"
        class="d-flex justify-content-center justify-content-sm-end"
      >
        <b-pagination
          v-model="tableSettings.currentPage"
          :total-rows="tableSettings.totalRows"
          :per-page="tableSettings.perPage"
          first-number
          last-number
          pills
          prev-text="Prev"
          next-text="Next"
          aria-controls="table"
        />
      </b-col>
    </b-row>

    <b-modal
      id="modal-show-details"
      scrollable
      no-close-on-backdrop
      title="Show Details"
      size="xl"
      @ok="onConfirmAction"
    >
      <b-tabs
        no-body
      >
        <b-tab
          title="Order Details"
          active
        >
          <b-row>
            <b-col
              cols="12"
              sm="4"
            >
              <b-form-group>
                <label for="created_at">Transaction Date</label>
                <b-input
                  id="created_at"
                  v-model="salesOrderDetails.created_at"
                  name="created_at"
                  type="text"
                  :disabled="true"
                />
              </b-form-group>
            </b-col>

            <b-col
              cols="12"
              sm="4"
            >
              <b-form-group>
                <label for="document_number">Document Number</label>
                <b-input
                  id="document_number"
                  v-model="salesOrderDetails.document_number"
                  name="document_number"
                  type="text"
                  :disabled="true"
                />
              </b-form-group>
            </b-col>

            <b-col
              cols="12"
              sm="4"
            >
              <b-form-group>
                <label for="customer_code">Customer Code</label>
                <b-input
                  id="customer_code"
                  v-model="salesOrderDetails.customer_code"
                  name="customer_code"
                  type="text"
                  :disabled="true"
                />
              </b-form-group>
            </b-col>

            <b-col
              cols="12"
            >
              <b-form-group>
                <label for="customer_name">Customer Name</label>
                <b-input
                  id="customer_name"
                  v-model="salesOrderDetails.customer_name"
                  name="customer_name"
                  type="text"
                  :disabled="true"
                />
              </b-form-group>
            </b-col>

            <b-col
              cols="12"
              sm="3"
            >
              <b-form-group>
                <label for="term_name">Payment Term</label>
                <b-input
                  id="term_name"
                  v-model="salesOrderDetails.term_name"
                  name="term_name"
                  type="text"
                  :disabled="true"
                />
              </b-form-group>
            </b-col>

            <b-col
              cols="12"
              sm="3"
            >
              <b-form-group>
                <label for="discount_name">Discount Type</label>
                <b-input
                  id="discount_name"
                  v-model="salesOrderDetails.discount_name"
                  name="discount_name"
                  type="text"
                  :disabled="true"
                />
              </b-form-group>
            </b-col>

            <b-col
              cols="12"
              sm="3"
            >
              <b-form-group>
                <label for="discount_percentage">Discount %</label>
                <b-input
                  id="discount_percentage"
                  v-model="salesOrderDetails.discount_percentage"
                  name="discount_percentage"
                  type="text"
                  :disabled="true"
                />
              </b-form-group>
            </b-col>

            <b-col
              cols="12"
              sm="3"
            >
              <b-form-group>
                <label for="shipment_type">Shipment Type</label>
                <b-input
                  id="shipment_type"
                  :value="salesOrderDetails.warehouse_pick_up === 1 ? 'For Pick Up' : 'For Delivery'"
                  name="shipment_type"
                  type="text"
                  :disabled="true"
                />
              </b-form-group>
            </b-col>

            <b-col
              cols="12"
            >
              <b-form-group>
                <label for="remarks">Remarks</label>
                <b-textarea
                  id="remarks"
                  v-model="salesOrderDetails.remarks"
                  name="remarks"
                  type="text"
                  :disabled="true"
                  rows="6"
                  max-rows="6"
                />
              </b-form-group>
            </b-col>

            <b-col
              cols="12"
            >
              <b-row>
                <b-col
                  cols="12"
                  class="mb-2"
                >
                  <strong>Ordered Item(s)</strong>
                </b-col>
                <b-col cols="12">
                  <b-table
                    striped
                    hover
                    responsive
                    :items="orderItems"
                    :fields="tableOrderItemSettings.fields"
                  >
                    <template #cell(index)="data">
                      {{ data.index + 1 }}
                    </template>
                  </b-table>
                </b-col>
                <b-col
                  cols="12"
                  class="d-flex justify-content-end align-items-end"
                >
                  <b>Total Cost:&nbsp;<span>{{ numberFormatter(salesOrderDetails.total_cost) }}</span></b>
                </b-col>
              </b-row>
            </b-col>

            <b-col
              cols="12"
            >
              <ValidationObserver ref="form">
                <form
                  role="form"
                  novalidate
                >
                  <ValidationProvider
                    #default="{ errors }"
                    name="comment"
                    vid="comment"
                    rules="required|max:500"
                  >
                    <b-form-group>
                      <label>Approver Comment</label>
                      <b-form-textarea
                        id="comment"
                        v-model="salesOrderDetails.comment"
                        placeholder="Enter Comment..."
                        :state="errors.length > 0 ? false : null"
                        rows="6"
                        max-rows="6"
                      />
                      <div
                        v-if="errors.length > 0"
                        class="invalid-feedback"
                      >
                        <span>{{ errors[0] }}</span>
                      </div>
                    </b-form-group>
                  </ValidationProvider>
                </form>
              </ValidationObserver>
            </b-col>
          </b-row>
        </b-tab>

        <b-tab
          v-if="(user ? user.role : '') === 'logistics'"
          title="Transaction History"
        >
          <b-card
            v-for="(product, index) in transactionHistory.data"
            :key="index"
            no-body
          >
            <p
              v-b-toggle="`collapse-${index}-inner`"
              class="pl-2 py-2 m-0"
              :style="`cursor:pointer;  ${product.status === 'Delivered' || product.status === 'Picked-up' ? 'background-color:#06C270;color:white' : 'background-color:#dee2e6'}`"
            >
              Transaction Date: {{ dateFormatter(product.delivered_at) }}
            </p>
            <!-- <b-button v-b-toggle.collapse-1-inner size="sm">Toggle Inner Collapse</b-button> -->
            <b-collapse :id="`collapse-${index}-inner`">
              <p class="mt-2">
                Prepared By: {{ product.approved_by }}<br>
                Status: {{ product.status }}
              </p>
              <b-table
                striped
                hover
                :items="product.transaction_items"
                :fields="transactionHistory.fields"
              >
                <template #cell(index)="data">
                  {{ data.index + 1 }}
                </template>

                <template #cell(product_name)="data">
                  {{ data.item.sale_order_detail.product_name }}
                </template>
              </b-table>
            </b-collapse>
          </b-card>
        </b-tab>
      </b-tabs>

      <template #modal-footer="{ ok, cancel }">
        <b-button
          variant="success"
          :disabled="isBusy"
          @click="onSetAction(ok, 'Approve', 'Approved')"
        >
          Approve
        </b-button>

        <b-button
          v-if="(user ? user.role : '') === 'sra' || (user ? user.role : '') === 'cco'"
          variant="secondary"
          :disabled="isBusy"
          @click="onSetAction(ok, 'Reject', 'Rejected')"
        >
          Reject
        </b-button>

        <b-button
          variant="danger"
          @click="cancel()"
        >
          Close Window
        </b-button>
      </template>
    </b-modal>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import { AxiosError, SharedListService, SharedSalesOrderService } from '@/services'
import formatter from '@/mixins/formatter'

export default {
  name: 'SalesOrderComponents',

  mixins: [formatter],

  data () {
    return {
      isBusy: false,
      distributions: [],
      orderItems: [],
      current: {
        action: '',
        status: ''
      },
      filter: {
        distribution: 'All'
      },
      salesOrderDetails: {
        id: 0,
        status: '',
        created_at: '',
        document_number: '',
        customer_code: '',
        customer_name: '',
        term_name: '',
        discount_name: '',
        discount_percentage: 0,
        total_cost: 0,
        warehouse_pick_up: 0,
        remarks: '',
        comment: '',
        transactions: [],
        sale_order_details: []
      },
      transactionHistory: {
        fields: [
          'index',
          { key: 'product_name' },
          { key: 'quantity' }
        ],
        data: []
      },
      tableSettings: {
        perPage: 10,
        pageOptions: [10, 25, 50, 100],
        totalRows: 0,
        currentPage: 1,
        sortBy: '',
        sortDesc: false,
        sortDirection: 'asc',
        filter: '',
        filterOn: [],
        fields: [
          'index',
          'actions',
          { key: 'created_at', label: 'transaction date', formatter: this.dateTimeFormatter, sortable: true },
          { key: 'document_number.' },
          { key: 'customer_name' },
          { key: 'status' },
          { key: 'group_approver' }
          // { key: 'warehouse_pick_up', label: 'Warehouse Pick Up ?', sortable: true },
          // { key: 'is_close', label: 'Is Close ?', sortable: true },
        ]
      },
      tableOrderItemSettings: {
        fields: [
          'index',
          { key: 'category_name' },
          { key: 'product_name' },
          { key: 'quantity' },
          { key: 'delivered_quantity' },
          { key: 'pending_deliver', label: this.user?.role === 'logistics' ? 'To deliver' : 'Pending Deliver' },
          { key: 'price', formatter: this.numberFormatter, tdClass: 'text-right' },
          { key: 'total_price', formatter: this.numberFormatter, tdClass: 'text-right' }
        ]
      }
    }
  },

  computed: {
    ...mapGetters({
      user: 'auth/user'
    }),

    filterDistributions () {
      return [].concat([{ value: 'All', text: 'All' }], this.distributions)
    }
  },

  mounted () {
    this.fetchDistributionList()
  },

  methods: {
    async myProvider (ctx) {
      try {
        const { data } = await SharedSalesOrderService.getPendingSalesOrder(`page=${ctx.currentPage}&per_page=${ctx.perPage}&sort=${ctx.sortBy}&sort_desc=${ctx.sortDesc}&filter_text=${ctx.filter}&tracker=0&distribution_id=${this.filter.distribution}`)
        this.tableSettings.totalRows = data.total_rows
        return data.data
      } catch {
        return []
      }
    },

    async fetchDistributionList () {
      this.distributions = []
      await SharedListService.getDistributionList().then(
        ({ data }) => {
          data.forEach(item => {
            this.distributions.push({
              value: item.id,
              text: item.distribution_name
            })
          })
        }
      )
    },

    showSODetailsForm (item) {
      this.salesOrderDetails.id = item.id
      this.salesOrderDetails.created_at = this.dateTimeFormatter(item.created_at)
      this.salesOrderDetails.document_number = item.document_number
      this.salesOrderDetails.customer_code = item.customer_code
      this.salesOrderDetails.customer_name = item.customer_name
      this.salesOrderDetails.term_name = item.term_name
      this.salesOrderDetails.discount_name = item.discount_name
      this.salesOrderDetails.discount_percentage = item.discount_percentage
      this.salesOrderDetails.remarks = item.remarks
      this.salesOrderDetails.comment = ''

      if (this.user?.role === 'logistics') {
        this.salesOrderDetails.warehouse_pick_up = item.warehouse_pick_up
        this.salesOrderDetails.transactions = item.transactions
        this.salesOrderDetails.sale_order_details = item.sale_order_details
      }

      const data = item.sale_order_details.map(x => {
        return {
          id: x.id,
          category_name: x.category_name,
          product_name: x.product_name,
          quantity: x.quantity,
          price: x.price,
          total_price: Number(x.quantity, 2) * Number(x.price, 2),
          quantity_out: 0,
          delivered_quantity: this.getQuantity(x.id, item, ['Delivered', 'Picked-up']),
          disabled: x.quantity_out === x.quantity,
          pending_deliver: this.getQuantity(x.id, item, ['Pending'])
        }
      })

      this.orderItems = data
      this.transactionHistory.data = item?.transactions || []
      const sumTotalPrice = data.reduce((acc, ele) => {
        return acc + parseFloat(ele.total_price)
      }, 0)

      this.salesOrderDetails.total_cost = sumTotalPrice

      this.$bvModal.show('modal-show-details')
    },

    getQuantity (id, item, status) {
      return item?.transactions.filter(el => {
        if (status.indexOf(el.status) !== -1) {
          return el
        }
      })
        .flatMap(el => {
          return el?.transaction_items.map((t, ti) => {
            if (id === t.sale_order_detail_id) {
              return t
            } else {
              return { quantity: 0 }
            }
          })
        })?.reduce((prev, current) => {
          // eslint-disable-next-line no-return-assign
          return prev += current.quantity
        }, 0)
    },

    getStatus (row) {
      let newStatus = row.status

      if (row.group_approver === 'Warehouse') {
        if (row.status === 'Approved') {
          newStatus = 'Fully Serve'
        } else if (row.status === 'Partially Approved') {
          newStatus = 'Partially Serve'
        } else {
          newStatus = row.status
        }
      } else if (row.group_approver === 'Logistics') {
        if (row.status === 'Pending') {
          newStatus = 'For Delivery'
        } else if (row.status === 'Delivered') {
          newStatus = 'Fully Delivered'
        } else {
          newStatus = row.status
        }
      }
      return newStatus
    },

    onSetAction (callback, action, status) {
      this.current.action = action
      this.current.status = status
      callback()
    },

    async onConfirmAction (bvModalEvt) {
      bvModalEvt.preventDefault()
      await this.$refs.form.validate().then(async success => {
        if (success) {
          this.$swal.fire({
            icon: 'question',
            title: `Are you sure you want to ${this.current.action} this sales order?`,
            showCancelButton: true,
            confirmButtonColor: '#06C270',
            confirmButtonText: 'Confirm',
            cancelButtonColor: '#FF2929',
            showLoaderOnConfirm: true,
            preConfirm: async () => {
              return this.handleStatus(this.current.status)
            },
            allowOutsideClick: () => !this.$swal.isLoading()
          })
        } else {
          this.$bvModal.msgBoxOk('Error in saving data. make sure that all fields are filled.', {
            title: 'Validation Error',
            size: 'md',
            buttonSize: 'sm',
            okVariant: 'danger'
          })
        }
      })
    },

    async handleStatus (status) {
      return new Promise((resolve, reject) => {
        if (this.user?.role === 'logistics') {
          this.salesOrderDetails.status = 'Delivered'
        } else {
          this.salesOrderDetails.status = status
        }

        this.isBusy = true
        SharedSalesOrderService.put(this.salesOrderDetails)
          .then(response => {
            this.isBusy = false
            this.$bvModal.hide('modal-show-details')
            this.$swal
              .fire({
                icon: 'success',
                title: 'Successful',
                text: response.data.message,
                confirmButtonColor: '#06C270',
                confirmButtonText: 'Dismiss'
              })
              .then(() => {
                this.$refs.table.refresh()
              })
          })
          .catch(error => {
            this.isBusy = false
            if (error instanceof AxiosError) {
              if (error.code === 422) {
                resolve(error)
              }
            }
          })
      })
    },

    onFilterChange () {
      this.$refs.table.refresh()
    }
  }
}
</script>
